import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { mdiContentSaveOutline } from '@mdi/js';
import { Form } from 'antd';

import AntButtonWithMDI from '../../components/AntButtonWithMDI';

import { antNotification, capitalizeAndTranslateMsg, translateMsg } from '../../mainUtils';
import { ApiContext } from '../../api/ApiContextProvider';

function SendActorBtn({
  actorForm,
  actorType,
  btnSize,
  disabled,
  mode,
  onFinallyCallback,
  onSubmitCallback,
}) {
  const {
    requestCreateActor,
    requestUpdateActor,
    downLoadBackupFileRequest,
  } = useContext(ApiContext);

  const {
    resetFields, validateFields, getFieldsValue, getFieldValue, getFieldsError,
  } = actorForm || {};

  const isCreateMode = mode === 'create';
  const isUpdateMode = mode === 'update';

  const createOrUpdateActor = async (formData) => {
    const removedActorList = getFieldValue('removeActorsList');
    const addActorsList = getFieldValue('addActorsList');
    const newActorsNotEmpty = addActorsList?.length > 0;
    const removedActorsNotEmpty = removedActorList?.length > 0;

    const actorResponse = await (isCreateMode ? requestCreateActor(formData) : requestUpdateActor(formData));

    const { actor, backup_filename: backupName } = actorResponse || {};
    const {
      uinfo: {
        first_name: fName,
        last_name: lName,
      } = {},
      uuid: responseActorUUID,
    } = actor || {};

    if (backupName) {
      downLoadBackupFileRequest(backupName, `${fName}_${lName}`);
    }

    if (newActorsNotEmpty || removedActorsNotEmpty) {
      await requestUpdateActor({
        actor_type: actorType,
        uuid: isCreateMode ? responseActorUUID : formData?.uuid,
        ...(addActorsList && newActorsNotEmpty ? { add_actors_list: addActorsList } : {}),
        ...(removedActorList && removedActorsNotEmpty ? { remove_actors_list: removedActorList } : {}),
      }).then(() => {
        onSubmitCallback?.(actor);
      })
        .catch(() => {})
        .finally(() => {
          // resetFields();
          onFinallyCallback?.();
        });
    } else {
      onSubmitCallback?.(actor);
      onFinallyCallback?.();
    }
  };

  const onClick = async () => {
    const changedInputs = getFieldsValue(null, (meta) => meta.touched);

    // console.log('CHANGED CHANGED CHANGED CHANGED', changedInputs);

    const {
      internal: actorInternalValue,
      uuid,
      reserve_domains,
      password: actorPassword,
    } = getFieldsValue([
      'addActorsList',
      'removeActorsList',
      'internal',
      'uuid',
      'password',
      'reserve_domains',
      'service_domain',
      'service_name',
    ]) || {};

    const {
      birthday,
      description,
      email,
      first_name,
      group_name,
      initial_key,
      listingGroupFlag,
      last_name,
      login,
      newKeyPair: generate_new_keys_for_user,
      password,
      phone_number,
      service_domain,
      service_name,
      isRobot,
      typeOfUser,
      weight,
      internal,
      internalemail,
      internal_domain,
    } = changedInputs || {};

    try {
      validateFields();

      const data = {
        actor_type: actorType,
        ...(typeOfUser ? { actor_type: typeOfUser } : {}),
        ...(typeOfUser && typeOfUser === 'user' ? { generate_new_keys_for_user: true } : {}),
        ...(initial_key ? { initial_key } : {}),
        ...(generate_new_keys_for_user ? { generate_new_keys_for_user } : {}),
        ...(birthday ? { birthday: dayjs(birthday).format('YYYY-MM-DD') } : {}),
        ...(uuid ? { uuid } : {}),
        uinfo: {
          ...(description ? { description } : {}),
          ...(first_name ? { first_name } : {}),
          ...(service_name ? { service_name } : {}),
          ...(service_domain ? { service_domain } : {}),
          ...(reserve_domains ? { reserve_domains } : {}),
          ...(internal_domain ? { internal_domain } : {}),
          ...(internal?.toString() || actorInternalValue?.toString() ? { internal_user: internal || actorInternalValue } : (isCreateMode ? { internal_user: true } : {})),
          ...(weight ? { weight } : {}),
          ...(internalemail ? { internal_email: internalemail } : {}),
          ...(group_name ? { group_name } : {}),
          ...(last_name ? { last_name } : {}),
          ...(email ? { email } : {}),
          ...(isRobot ? { isRobot } : {}),
          ...(password ? { password } : (actorPassword ? { password: actorPassword } : {})),
          ...(phone_number ? { phone_number } : {}),
          ...(login ? { login } : {}),
          ...(birthday ? { birthday: dayjs(birthday).format('YYYY-MM-DD') } : {}),
          ...(listingGroupFlag ? { create_without_listing_group: listingGroupFlag } : {}),
        },
      };

      await createOrUpdateActor(data);
    } catch (error) {
      console.log('error', error);
      antNotification.error(translateMsg('auth.notifications.invalid_data', 'invalid data entered'));
    }
  };

  return (
    <Form.Item shouldUpdate noStyle>
      {() => (
        <AntButtonWithMDI
          className="button-primary"
          onClick={onClick}
          disabled={disabled || !!getFieldsError().filter(({ errors }) => errors.length).length}
          size={btnSize}
          pathForMdi={isCreateMode ? null : mdiContentSaveOutline}
          label={isCreateMode
            ? capitalizeAndTranslateMsg('auth.buttons.create_actor', 'create actor')
            : capitalizeAndTranslateMsg('54origins.verb.save', 'save')}
        />
      )}
    </Form.Item>

  );
}

export default SendActorBtn;

SendActorBtn.propTypes = {
  actorForm: PropTypes.any,
  actorType: PropTypes.string,
  btnSize: PropTypes.string,
  disabled: PropTypes.bool,
  mode: PropTypes.string,
  onFinallyCallback: PropTypes.func,
  onSubmitCallback: PropTypes.func,
};
