import {
  mdiClose,
  mdiContentCopy,
  mdiInformationOutline,
  mdiSquareEditOutline,
  mdiTrashCanOutline,
  mdiTune,
  mdiFilterOutline,
  mdiAccountGroupOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import {
  Card,
  Col,
  Collapse,
  Form,
  Row,
  Skeleton,
  Space,
  Spin,
  Tag,
} from 'antd';
import { isString } from 'lodash';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, {
  memo,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import AddActorToBanGroupBtn from '../AddActorToBanGroupBtn';
import AntButtonWithMDI from '../../../../components/AntButtonWithMDI';
import DateLabelFormat54origins from '../../../../54origins/components/DateLabelFormat54origins';
import DeleteButton from '../../../../components/DeleteButton';
import GroupInfoFormItems from '../GroupInfoFormItems';
import InternalOrExternalTag from '../../InternalOrExternalTag';
import SendActorBtn from '../../SendActorBtn';
import ServiceInfoFormItems from '../ServiceInfoFormItems';
import UserInfoFormItems from '../UserInfoFormItems';
import UtilityBtnForActorInfo from '../UtilityBtnForActorInfo';
import AddActorsForActorBtn from '../AddActorsForActorBtn';
import './ActorInfo.scss';

import {
  textToClipboard,
  capitalizeAndTranslateMsg,
} from '../../../../mainUtils';
import {
  getActorsInfo, getDefaultAdminGroupUUID, getDefaultBanGroupUUID,
  getDefaultGroupNames,
  isActorsFetching,
  isInfoFetching,
} from '../../../selectors';
import { getNameForActor } from '../../../../54origins/utils54origins';
import useAuthActorsRequests from '../../../hooks/useAuthActorsRequests';
import useURLParams from '../../../../hooks/useURLParams';
import { getMeIsRootOrAdmin } from '../../../../profile/selectors';
import ActorListOfTags from '../../../ActorListOfTags';
import BaseCard from '../../../../_ui/BaseCard/BaseCard';
import BaseIcon from '../../../../_ui/BaseIcon/BaseIcon';
import BaseTag from '../../../../_ui/BaseTag/BaseTag';

function ActorInfo({
  onSuccessUpdate,
  onSuccessDelete,
  applyFilter,
  hideElements = [],
  isCollapsed = false,
}) {
  const [actorForm] = Form.useForm();

  const { requestGetActorOnAuth } = useAuthActorsRequests();

  const {
    getURLParams, routeNavigateAndClearParams, clearSearchParams, setSearchParams,
  } = useURLParams();

  const actorData = useSelector(getActorsInfo);
  const isFetching = useSelector(isInfoFetching);
  const isActorListFetching = useSelector(isActorsFetching);
  const defaultGroupNames = useSelector(getDefaultGroupNames);
  const meIsRootOrAdmin = useSelector(getMeIsRootOrAdmin);
  const adminGroupUUID = useSelector(getDefaultAdminGroupUUID);
  const banGroupUUID = useSelector(getDefaultBanGroupUUID);

  const [editMode, setEditMode] = useState(false);
  const [fieldsIsChanged, setFieldsIsChanged] = useState(false);

  const [collapsed, setCollapsed] = useReducer((state) => !state, false);

  const {
    groups: urlGroups,
    actor_type: urlActorType,
    uuid: actorURLUUID,
  } = getURLParams();

  const {
    initial_key = '',
    root_perms_signature: rootPermsSignature = false,
    uuid: actorUUID,
    created,
    actor_type: actorType,
    uinfo: {
      birthday = null,
      date_updated: dateUpdated,
      description = '',
      email = '',
      first_name = '',
      group_name = '',
      groups = [],
      internal_domain = '',
      internal_user: internal = false,
      isRobot = false,
      last_name = '',
      listing_group = '',
      login = '',
      password: actorPassword,
      phone_number = '',
      public_interfaces = [],
      reserve_domains = '',
      service_domain = '',
      service_name = '',
      weight = '',
    } = {},
  } = actorData || {};

  const isUser = actorType === 'user' || actorType === 'classic_user';
  const isService = actorType === 'service';
  const isGroup = actorType === 'group';

  const serviceInitialValues = {
    description,
    groups,
    initial_key,
    listing_group,
    service_domain,
    service_name,
    internal_domain,
    reserve_domains,
    internal,
  };

  const userInitialValues = {
    birthday: birthday ? dayjs(birthday, 'YYYY-MM-DD') : null,
    email,
    first_name,
    groups,
    initial_key,
    internal,
    isRobot,
    last_name,
    login,
    phone_number,
    newKeyPair: false,
    typeOfUser: actorType,
  };

  const groupInitialValues = {
    group_name,
    weight,
    description,
    internal,
    public_interfaces,
  };

  const filterActive = isString(urlGroups)
    && urlGroups === actorURLUUID
    && !urlActorType;

  const defaultTranslatePath = 'auth.validation.messages.';

  const validateMessages = {
    required: capitalizeAndTranslateMsg('required', 'is required'),
    string: {
      len: capitalizeAndTranslateMsg(`${defaultTranslatePath}len`, 'must be exactly ${len} characters'),
      min: capitalizeAndTranslateMsg(`${defaultTranslatePath}min`, 'must be at least ${min} characters'),
      max: capitalizeAndTranslateMsg(`${defaultTranslatePath}max`, 'cannot be longer than ${max} characters'),
    },
    types: {
      email: capitalizeAndTranslateMsg(`${defaultTranslatePath}email`, 'is not a valid email'),
      number: capitalizeAndTranslateMsg(`${defaultTranslatePath}number`, 'must be a number type'),
      url: capitalizeAndTranslateMsg(`${defaultTranslatePath}url`, 'is not a valid URL'),
    },
  };

  const isRequired = {
    required: true,
    whitespace: true,
    message: 'The field is required and cannot be empty',
  };

  const rules = {
    user: {
      first_name: [{ max: 50 }, isRequired],
      last_name: [{ max: 50 }, isRequired],
      password: [{ min: 4 }, { max: 50 }, isRequired],
      password_confirmation: [
        {
          required: true,
          message: 'Please confirm your password!',
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
          },
        }),
      ],
      login: [{ min: 3 }, { max: 36 }],
      initial_key: [{ len: 130 }],
      phone_number: [{
        pattern: /^[0-9 ()+-]+$/,
        message: 'Phone number is not valid',
      }],
    },
    group: {
      group_name: [isRequired, { min: 1 }, { max: 50 }],
      weight: [{ type: 'number' }, {
        required: true,
        message: 'The field is required and cannot be empty',
      }],
    },
    service: {
      service_name: [isRequired, { min: 1 }, { max: 50 }],
      service_domain: [{ type: 'url' }, isRequired],
      initial_key: [{ len: 130 }, isRequired],
    },
  };

  const isDefault = isGroup && defaultGroupNames.includes(group_name);

  const additionalTag = () => {
    const tagName = rootPermsSignature ? 'root' : 'default';

    return (
      <>
        {(rootPermsSignature || isDefault) && (
          <BaseTag className={`tag-${rootPermsSignature ? 'purple' : 'gray'} ml-2`}>
            {capitalizeAndTranslateMsg(`auth.headers.${tagName}`, tagName).toUpperCase()}
          </BaseTag>
        )}
      </>
    );
  };

  const onApplyFilter = () => {
    applyFilter([
      {
        key: 'uinfo.groups',
        value: actorURLUUID,
        label: 'Group with perms 2',
        className: '',
      },
    ]);
  };

  const getActorInitFormValues = () => {
    switch (actorType) {
      case 'classic_user':
      case 'user':
      case 'phantom':
        return userInitialValues;
      case 'group':
        return groupInitialValues;
      case 'service':
        return serviceInitialValues;
      default:
        return [];
    }
  };

  const editBtnOnClick = useCallback(() => {
    setEditMode(true);
  }, []);

  const onSavePublicInterfaceCallback = () => initFunc();

  const setInitActorForm = () => {
    const data = {
      ...getActorInitFormValues(),
      password: actorPassword,
      uuid: actorUUID,
      removeActorsList: [],
      addActorsList: [],
    };

    actorForm.setFieldsValue(data);
  };

  const onCancel = () => {
    setEditMode(false);
    setFieldsIsChanged(false);

    setInitActorForm();
  };

  const updateActorCallback = ({ addActorsList, removeActorsList }) => {
    if (editMode && (addActorsList || removeActorsList)) {
      actorForm.setFieldsValue({
        addActorsList,
        removeActorsList,
      });

      setFieldsIsChanged(true);
    } else {
      initFunc();
      onSuccessUpdate?.();
    }
  };

  const getActorForm = () => {
    const infoProps = {
      actorForm,
      actorType,
      editBtnOnClick,
      setFieldsIsChanged,
      editMode,
      actorData,
      actorUUID,
      validateMessages,
      onSuccessUpdate,
    };

    switch (actorType) {
      case 'classic_user':
      case 'user':
      case 'phantom':
        return (
          <UserInfoFormItems
            {...infoProps}
            rules={rules.user}
            hideElements={['oldPassword', 'passwordConfirmation']}
          />
        );
      case 'group':
        return (
          <GroupInfoFormItems
            {...infoProps}
            rules={rules.group}
          />
        );
      case 'service':
        return (
          <ServiceInfoFormItems
            {...infoProps}
            rules={rules.service}
            onSuccessActorUpdate={onSuccessUpdate}
          />
        );
      default:
        return null;
    }
  };

  const checkIsNotHide = (name) => !hideElements.includes(name);

  const initFunc = async () => {
    await requestGetActorOnAuth({ uuid: actorURLUUID })
      .then(() => {})
      .catch(() => clearSearchParams(['uuid']));
  };

  useEffect(() => {
    if (actorURLUUID && actorURLUUID !== actorUUID) {
      initFunc();
    }

    onCancel();
  }, [actorURLUUID]);

  useEffect(() => {
    if (!isFetching) {
      setInitActorForm();
    }
  }, [actorUUID, isFetching, editMode]);

  return (
    <Spin
      spinning={isFetching || isActorListFetching || !actorUUID}
    >
      <Form
        layout="vertical"
        autoComplete="off"
        form={actorForm}
        validateMessages={validateMessages}
        onFieldsChange={() => {
          setFieldsIsChanged(true);
        }}
      >

        <BaseCard
          className={`${isCollapsed ? '' : 'border-blue'}`}
          actions={isCollapsed ? [<CollapseBtn onClick={setCollapsed} />] : null}
        >
          <Row
            gutter={[16, 0]}
            wrap={false}
          >
            <Col span={6}>
              <h4 className="header-primary">
                {capitalizeAndTranslateMsg('auth.headers.actor_info', 'actor info')}
              </h4>
            </Col>
            <Col span={18}>
              <Space
                size="small"
                className="w-full justify-end"
                align="start"
              >
                {isGroup && checkIsNotHide('applyFilterBtn') && (
                  <AntButtonWithMDI
                    className="button-secondary-outlined"
                    size="small"
                    onClick={onApplyFilter}
                    disabled={isFetching || filterActive}
                    label={capitalizeAndTranslateMsg('auth.buttons.apply_filter', 'apply filter')}
                    pathForMdi={mdiFilterOutline}
                    iconClassName="mr-1"
                  />
                )}
                {editMode && (
                <>
                  <AntButtonWithMDI
                    className="button-secondary-outlined"
                    size="small"
                    onClick={onCancel}
                    disabled={isDefault || isFetching}
                    pathForMdi={mdiClose}
                    iconClassName="mr-1"
                    label={capitalizeAndTranslateMsg('auth.buttons.cancel', 'cancel')}
                  />
                  <SendActorBtn
                    actorForm={actorForm}
                    actorType={actorType}
                    disabled={isDefault || isFetching || !fieldsIsChanged}
                    mode="update"
                    actorUUID={actorURLUUID}
                    btnSize="small"
                    onSubmitCallback={onSuccessUpdate}
                    onFinallyCallback={onCancel}
                    fieldsIsChanged={fieldsIsChanged}
                  />
                </>
                )}
                {!editMode && (
                <AntButtonWithMDI
                  className="button-primary-outlined"
                  size="small"
                  disabled={isDefault || isFetching}
                  pathForMdi={mdiSquareEditOutline}
                  onClick={editBtnOnClick}
                  label={capitalizeAndTranslateMsg('54origins.verb.edit', 'edit')}
                />
                )}
                <DeleteButton
                  onSuccess={onSuccessDelete}
                  uuid={actorURLUUID}
                  disabled={isDefault || isFetching}
                  id={55}
                >
                  <AntButtonWithMDI
                    className="button-danger-outlined"
                    size="small"
                    disabled={isDefault || isFetching}
                    pathForMdi={mdiTrashCanOutline}
                    label={capitalizeAndTranslateMsg('auth.buttons.remove_actor', 'remove actor')}
                  />
                </DeleteButton>
              </Space>
            </Col>
          </Row>
          <hr className="my-6" />
          <Row>
            <Col
              span={24}
              className="flex items-center"
            >
              <Skeleton
                loading={isFetching}
                paragraph={false}
                title={{
                  width: '50%',
                  style: {
                    margin: 0,
                    height: 24,
                  },
                }}
                active
                round
              >
                <h3 className="header-primary m-0">
                  {getNameForActor(actorData)}
                </h3>
                <InternalOrExternalTag internal={internal} className="ml-1" />
                <BaseTag className={`tag-${actorType} ml-2`}>
                  {capitalizeAndTranslateMsg(`auth.headers.${actorType}`, actorType)}
                </BaseTag>
                {additionalTag()}
                {/* <RobotActorTag isRobot={isRobot} /> */}
                <ActorListOfTags actor={actorData} />
              </Skeleton>
            </Col>
            <Col
              span={24}
              className="flex items-center mt-2"
            >
              <h5 className="header-primary">
                UUID:
                {' '}
                {actorURLUUID}
              </h5>
              <AntButtonWithMDI
                className="button-primary-link ml-2"
                size="small"
                onClick={() => textToClipboard(actorURLUUID)}
                pathForMdi={mdiContentCopy}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col span={24}>
              <Space align="top" wrap>
                {actorUUID && (
                  <AddActorsForActorBtn
                    actorData={actorData}
                    iconModalPath={mdiAccountGroupOutline}
                    onSaveCallBack={updateActorCallback}
                    editMode={editMode}
                  />
                )}
                {isUser && (
                  <AddActorToBanGroupBtn
                    actor={actorData}
                    onSaveCallBack={onSuccessUpdate}
                  />
                )}
                {checkIsNotHide('permsBtn') && !editMode && actorUUID !== adminGroupUUID && actorUUID !== banGroupUUID && (
                  <AntButtonWithMDI
                    className="button-primary-outlined"
                    onClick={() => routeNavigateAndClearParams('/admin/actors/permissions', {
                      uuid: actorUUID,
                      tab: 'permDashboard',
                    })}
                    size="small"
                    pathForMdi={mdiTune}
                    sizeMdi={1.2}
                    iconClassName="mr-2"
                    label={capitalizeAndTranslateMsg('auth.headers.permissions_setting', 'permissions')}
                  />
                )}

                {checkIsNotHide('actorsPermsOnServiceBtn') && !editMode && isService && (
                  <AntButtonWithMDI
                    className="button-primary-outlined"
                    onClick={() => routeNavigateAndClearParams('/admin/actors/perms-on-service', { uuid: actorUUID })}
                    size="small"
                    pathForMdi={mdiTune}
                    sizeMdi={1.2}
                    iconClassName="mr-2"
                    label={capitalizeAndTranslateMsg('auth.headers.permis', 'actors with perms on service')}
                  />
                )}
                {isService && meIsRootOrAdmin && (
                  <AntButtonWithMDI
                    size="small"
                    className="button-primary-outlined w-full"
                    onClick={() => {
                      setSearchParams({
                        modal: 'existOnService',
                      });
                    }}
                    label="existing users"
                  />
                )}

                <UtilityBtnForActorInfo
                  actorData={actorData}
                  actorType={actorType}
                  actorUUID={actorUUID}
                  onSuccessUpdate={onSuccessUpdate}
                  onSavePublicInterfaceCallback={onSavePublicInterfaceCallback}
                />
              </Space>
            </Col>
          </Row>
          <hr className={collapsed ? 'my-6' : 'mt-6 mb-0'} />
          {isCollapsed ? (
            <Collapse
              className="card-collapse"
              activeKey={collapsed ? '1' : '0'}
            >
              <Collapse.Panel
                header={capitalizeAndTranslateMsg('auth.headers.detailed_information', 'detailed information')}
                key="1"
                forceRender
              >
                {getActorForm()}
              </Collapse.Panel>
            </Collapse>
          ) : getActorForm()}

          {/* <AddCustomFieldForActor /> */}

          <Row>
            <Col
              span={24}
              className="flex"
            >
              <DateLabelFormat54origins
                labelBeforeDate="created:"
                date={created}
                className="ml-auto"
                textType="secondary"
                format="DD-MM-YYYY | HH:mm"
              />
            </Col>
            {dateUpdated && (
              <Col
                span={24}
                className="flex"
              >
                <DateLabelFormat54origins
                  labelBeforeDate="modify:"
                  date={dateUpdated}
                  className="ml-auto"
                  textType="secondary"
                  format="DD-MM-YYYY | HH:mm"
                />
              </Col>
            )}
          </Row>
        </BaseCard>
      </Form>
    </Spin>
  );
}

export default ActorInfo;

ActorInfo.propTypes = {
  applyFilter: PropTypes.func,
  onSuccessDelete: PropTypes.func,
  onSuccessUpdate: PropTypes.func,
  hideElements: PropTypes.array,
};

const CollapseBtn = memo(({
  onClick,
}) => (
  <div
    key="go_to_permissions_button"
    className="card-action"
    onClick={onClick}
  >
    <BaseIcon
      path={mdiInformationOutline}
      size={1.6}
      className="mr-2"
    />
    {capitalizeAndTranslateMsg('auth.headers.detailed_information', 'detailed information')}
  </div>
));
